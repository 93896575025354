<template>
  <div class="withdraw-usdt">
    <div class="c-wrper">
      <el-card class="box-card">
        <el-alert
          type="warning"
          show-icon
        >
        <template slot="title">
          <div style="font-weight:bold">{{$t('WithdrawUSDT.Tip')}}</div>
          <div style="font-weight:bold">{{$t('WithdrawUSDT.Tip2')}}</div>
        </template>
        </el-alert>
        <div class="fom-box">
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.WithdrawAddress') }}</div>
            <CInput class="inp" v-model="wAddr" oninput="value=value.replace(/[^a-zA-Z0-9]/g, '')"
                  type="text"
                  :invalid-feedback="errorBackAddress"
                  :is-valid="validatorAddress"
                  valid-feedback="" />
            <!-- <span class="tip">{{ $t('WithdrawUSDT.WithdrawAddressTip') }}</span> -->
            <span class="tip">Tron chain ONLY</span>
          </div>
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.WithdrawAmount') }}</div>
            <CInput v-model="wBalance" :invalid-feedback="errorBackBala" :is-valid="validatorBala" class="inp" type="text" />
          </div>
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.WithdrawCurrency') }}</div>
            <div class="vals form-group acvals">USDT</div>
          </div>
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.Rate') }}</div>
            <div class="vals form-group acvals">1</div>
          </div>
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.WithdrawalFee') }}</div>
            <!-- <div class="vals form-group acvals">3%</div> -->
            <div class="vals form-group acvals">{{ wFe }}</div>
          </div>
          <div class="fitem">
            <div class="flb">{{ $t('WithdrawUSDT.ReceivedUsdt') }}</div>
            <div class="vals form-group acvals">{{ reBalance }}</div>
          </div>
          <div class="fitem" style="justify-content: center">
            <div style="text-align: center; margin-top: 2rem">
              <button
                @click="submitUSDT"
                style="width: 328px"
                class="btn custer sbtn"
                type="button"
              >
                {{ $t('WithdrawUSDT.Submit') }}
              </button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { withdraw } from "../../api/http";
export default {
  data() {
    return {
      wAddr: "",
      wBalance: null,
      errorBackAddress: "",
      flags: false,
      errorBackBala: "",
      wFe: "3%"
    };
  },
  computed: {
    reBalance() {
      // return (Number(this.wBalance) * 0.97).toFixed(2);
      if(this.wFe == "3% + 1") {
        return (Number(this.wBalance) * 0.97 - 1).toFixed(2);
      }else {
        return (Number(this.wBalance) * 0.97).toFixed(2);
      }
    },
  },
  methods: {
    validatorBala(val) {
      if(val < 100) {
        this.wFe = "3% + 1"
      }else {
        this.wFe = "3%"
      }
    },
    validatorAddress(val) {
      let reg = /^T.*/;
      if (val === "") {
        this.errorBackAddress = "";
        this.flags = false;
        return;
      }
      if (reg.test(val)) {
        this.errorBackAddress = "";
        this.flags = false;
        return true;
      } else {
        this.errorBackAddress = this.$t(
          "RewardCenter.BindUSDTAcc.InvalidAddress"
        );
        this.flags = true;
        return false;
      }
    },
    submitUSDT() {
      if (this.wAddr == "" || this.wBalance == null || this.flags) {
        return;
      }
      let data = {
        type: "withdraw",
        uid: localStorage.getItem("uid"),
        amount: this.wBalance,
        pay_way: "USDT",
        account: this.wAddr,
      };
      withdraw(data).then((res) => {
        if (res.code == 200) {
          this.wAddr = "";
          this.wBalance = null;
          this.$message.success(res.message);
          this.$router.push('/card-detail/account-flow')
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.acvals{
    width: 183px;
    text-align: right;
}
.withdraw-usdt {
  padding-left: 2.6rem;
}
.c-wrper {
  width: 50%;
  margin: 1rem auto;
}
.fitem {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
}
.flb {
  width: 200px;
  font-weight: 500;
  color: #42474d;
  font-size: 14px;
  text-align: right;
  font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.vals{
    color: #42474d;
    font-size: 14px;
    font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.tip {
  color: #e6a23c;
  font-weight: bold;
  font-size: 12px;
  margin-left: 0.3rem;
}
.inp {
  margin-left: 1rem;
}
.form-group {
  margin: 0 !important;
  margin-left: 5rem !important;
}
.sbtn:hover {
  background: rgba(0, 190, 164, 0.8) !important;
}
.sbtn:focus {
  border-color: #00bea4;
  box-shadow: 0 0 0 0.25rem rgba(0, 190, 164, 0.5) !important;
}
.custer {
  background: #00bea4;
  color: #fff;
  width: 100%;
  outline: 0;
}
</style>